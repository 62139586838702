<template>
  <div class="plate">
    <div class="parking">
      <img src="@/assets/parking.png" alt="" srcset="">
    </div>
    <div class="forms">
      <van-tabs type="card" color="#1ca6ff" @change="tabChange">
        <van-tab title="手机号出场">
          <p class="tips">无牌车请输入进场的手机号码缴费离场。</p>
          <input type="number" v-model="phone" placeholder="请输入进场手机号">
        </van-tab>
        <van-tab title="车牌号出场">
          <p class="tips">输入车牌号预先缴费，出口不用等，停车更便捷！</p>
          <input type="text" v-model="plate" placeholder="请输入进场车牌号，例如：陕F12345">
        </van-tab>
      </van-tabs>
    </div>
    <button type="submit" class="openGate" @click="openGate">缴费离场</button>
  </div>
</template>

<script>
export default {
  name: "outer",
  title: "无牌车扫码进场",
  data() {
    return {
      phone: "",
      plate: "",
      locationId: "",
      parkCode: "",
    };
  },
  mounted() {
    const { locationId, parkCode } = this.$route.query;
    this.locationId = locationId;
    this.parkCode = parkCode;
    this.openGate()
  },
  methods: {
    tabChange() {
      this.phone = "";
      this.plate = "";
    },
    openGate() {
      const { phone, plate, locationId, parkCode } = this;
      this.$http.post(
        "/park-service-order/roadGateH5/insertRoadGateInRecord",
        { phone, plate, locationId, parkCode },
        (res) => {
          if (res.type == "1") {
            this.$toast("此通道暂无车辆出场");
          } else if (res.type == "2") {
            this.$router.push(`/order/detail?orderId=${res.orderInfo.id}`);
          } else if (res.type == "3") {
            this.$toast("未查询到信息");
          } else if (res.type == "4") {
            this.$toast("0元免费放行");
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.plate {
  height: 100%;
  background-color: #fff;
  .parking {
    text-align: center;
    padding: 0.9rem 0 0.5rem 0;
    img {
      width: 6.2rem;
      height: auto;
    }
  }
  .forms {
    width: 6.5rem;
    margin: 0 auto;
    .tips {
      line-height: 1rem;
      color: #666;
      text-align: center;
    }
    input {
      display: block;
      width: 100%;
      height: 0.9rem;
      margin: 0.5rem auto;
      background: #f3f3f3;
      border-radius: 8px;
      border: 0;
      text-align: center;
      font-size: 0.3rem;
    }
  }
  .openGate {
    display: block;
    width: 6.5rem;
    height: 0.9rem;
    margin: 1.4rem auto 0 auto;
    background: #1ca6ff;
    border-radius: 8px;
    border: 0;
    text-align: center;
    font-size: 0.3rem;
    color: #fff;
  }
}
</style>